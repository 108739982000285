// src/configLoader.js
const configs = {
  'sms-demo.pinestack.eu': () => import('../config/qa_wfe.js'),
  'pia.staging.pinestack.eu': () => import('../config/qa_wfe.js'),
  'pia.qa.pinestack.eu': () => import('../config/qa_wfe.js'),
  localhost: () => import('../config/hammerbrooklyn_wfe.js'), // Updated for localhost
  '192.168.178.103': () => import('../config/hammerbrooklyn_wfe.js'), // Windows/Android IP
  '192.168.0.47': () => import('../config/hammerbrooklyn_wfe.js'), // Mac/iOS IP
  'hammerbrooklyn.pinestack.eu': () => import('../config/hammerbrooklyn_wfe.js'), // Ensure correct path and extension
  // Add other tenants as needed
}

import { dbPromise } from '@/services/utils'

export const getStoredCustomer = async () => {
  const db = await dbPromise // Use the singleton dbPromise
  const tx = db.transaction('customer', 'readonly')
  const store = tx.objectStore('customer')
  const storedData = await store.get('selectedCustomer')
  return storedData ? storedData.value : 'PIA' // Default to PIA if not found
}

export const setStoredCustomer = async customer => {
  const db = await dbPromise // Use the singleton dbPromise
  const tx = db.transaction('customer', 'readwrite')
  const store = tx.objectStore('customer')
  await store.put({ id: 'selectedCustomer', value: customer }) // Store as an object
}

export const loadConfig = async () => {
  let customer = await getStoredCustomer()
  if (customer === 'HBC') {
    return (await import('../config/hammerbrooklyn_wfe.js')).default
  } else {
    return (await import('../config/qa_wfe.js')).default
  }
}

// export const loadConfig = async () => {
//   const hostname = window.location.hostname
//   for (const domain in configs) {
//     if (hostname.includes(domain)) {
//       const configModule = await configs[domain]()
//       return configModule.default // Make sure to return the default export of the module
//     }
//   }
//   throw new Error('Configuration not found for this domain: ' + hostname)
// }
